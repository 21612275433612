.menu-screen-title .grifter-regular {
  font-size: 2em;
}
.menu-screen-title .poppins-regular {
  font-size: 1em;
}

.menu-screen {
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  height: 100vh;
  min-height: 100vh;  
  max-height: 100vh;
  padding:1em;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  /* i hate css for this */
  /* change the above to have scrollbar there */
}



.menu-screen-title {
  display: inline-block;
}

.profile-pic {
  border-radius: 50%;
  width: 3.5em;
  float: right;
}

.search-dish {
  outline: none;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  color: white;
  border-radius: 10px;
  border-color: rgba(255, 255, 255, 0.05);
  border-width: 3px;
  padding: 1em 1em;
  width: 100%;
  max-height:3em;
  margin-top: 12px;
}

.input-icon {
  position: relative;
  display: inline;
}

.input-icon .search-icon {
  opacity: 70%;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.input-icon {
  cursor: pointer;
}

.food-category {
  background: rgba(255, 255, 255, 0.15);

  backdrop-filter: blur(3px);
  color: white;
  padding: 0.5em 1.4em;
  text-align: center;
  border-radius: 10px;
  font-size: 0.93em;
  cursor: pointer;
}

.menu-categories {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1em;
  /* gap: 0.5em; */
}

.category-selected {
  background-color: rgba(255, 255, 255, 0.4);
}

.dish-list {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  max-height: 100%;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  padding-bottom: 5em;
}

.dish-list::-webkit-scrollbar {
  display: none;
}
.dish-card {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  color: white;
  border-radius: 10px;
  width: 100%;
  min-height: 120px;
  max-height: 160px;
  display: flex;
}

.dish-name {
  font-size: 1.3em;
  font-weight: 800;
  display: block;
  opacity: 90%;
}

.dish-price {
  font-size: 1.2em;
  display: block;
  opacity: 80%;
}

.dish-left {
  padding: 1em 1.4em;
  width: 40%;
  flex: 1;
}

.dish-image {
  position: absolute;
  right: 0;
  height: 100%;
  object-fit: cover;
  width: 65%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}




@media (min-width: 768px) {
  .menu-screen {
    width:  740px;
    margin: 0 auto; /* Center the body */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .scroll-container {
    width: 738px;
  }

  .dish-card {
    width: 100%;
    display: flex;
    min-height: 160px;
  }

}