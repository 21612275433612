* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  background-color: #0f0f0f;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  height: 100vh;
  max-width: 740px;
  background-image: url("./snacks-bg.webp");
  background-position: top;
  background-position: right;
  background-repeat: no-repeat;
  background-position-y:-10% ;
  background-position-x:-10%;
  background-size: 110%;
}

.landing-container {
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: 20px;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #dbdbdb;
  color: black;
  filter: drop-shadow(0px 6.48px 6.48px rgba(0, 0, 0, 0.5));
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 15px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  align-items: center;
  font-size: 12px;
}

.button-focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px);
  color: white;
}



.auth-label {
  display: block;
  text-align: left;
  color: white;
  opacity: 80%;
  margin-left: 0.6em;
  margin-top: 1em;
  margin-bottom: 1.5px;
}
.auth-input {
  background: rgba(255, 255, 255, 0.15);
  width: 100%;
  border-radius: 15px;
  border-color: rgba(255, 255, 255, 0.05);
  border-width: 2px;
  padding: 15px 30px;
  outline: none;
}

.auth-container {
  width: 20em;
  display: flex;
  flex-direction: column;
  margin-top: 4em;
}

.input-container {
  position: relative;
  backdrop-filter: blur(2px);
}
.input-container .auth-input {
  padding-left: 3rem; /* Adjust this value to control the horizontal position of the input text */
  color: white;
}
.input-container .auth-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  opacity: 60%;
  stroke-width: .8px; 
}

.input-container .auth-icon-eye {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  opacity: 70%;
  stroke-width: 4px; 
  cursor: pointer;
}


@media (min-width: 768px) {
  body {
    width: 750px;
    margin: 0 auto;
    background-position-y:0 ;
    background-position-x:0;
    background-size: 100%;

  }
}