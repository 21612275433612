.fancy {
    --offset: 3px;
    /* overflow: hidden; */
    
}

/* Conic gradient */
.fancy::before { 
    content: '';
    background: conic-gradient(transparent 50deg, transparent, white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1;
    width: 100%;
    animation: rotate 2s linear infinite;
}

/* Overlay */
.fancy::after {
    content: '';
    background: inherit;
    border-radius: inherit;
    position: absolute;
    inset: var(--offset);
    height: calc(100% - 2 * var(--offset));
    width: calc(100% - 2 * var(--offset));
}

.fancy input {
    background: transparent;
    color: white;
    font-size: 1.5rem;
    position: absolute;
    inset: 0;
    z-index: 10;
    padding: 1.5rem;
}

@keyframes rotate {
    from {
        transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
    }

    to {
        transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
    }
}

@keyframes glow {
    0% {
        box-shadow: 0 0 10px rgba(252, 217, 127, 0.5);
    }
    50% {
        box-shadow: 0 0 25px rgba(252, 217, 127, 0.5);
    }
    100% {
        box-shadow: 0 0 2px rgba(252, 217, 127, 0.5);
    }
}

.animate-glow {
    animation: glow 3s ease-in-out 2;
}
