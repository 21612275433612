@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

root {
  --font-poppins: "Poppins", sans-serif;
  --font-inter: "Inter", sans-serif;
  --theme-color: "#fab317";
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.inter-regular {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Grifter";
  src: url("../src/static/grifterbold.otf") format("opentype");
}

.grifter-regular {
  font-family: "Grifter", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: url("../src/static/productsans.ttf") format("truetype");
}

.productsans-regular {
  font-family: "Product Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.productsans-bold {
  font-family: "Product Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

body {
  min-width: fit-content;
  max-width: 100vw;
}

.pwaComponent {

  width: 100%;

}

/* If the viewport is 768px or more wide (typical desktop), set the body width to 375px (typical mobile phone width) */
@media (min-width: 768px) {
  body {
    max-width: 750px;
    margin: 0 auto; /* Center the body */
  }

  .pwaComponent {
    width: 750px;
  }
}

